import { Breadcrumb, Row, Typography } from "antd"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import React, { useEffect, useState } from "react"
import { MaxWidth, WhiteSpace } from "../components/common"
import Container from "../components/container"
import { injectIntl } from "gatsby-plugin-intl"
import styled from "styled-components"
import SEO from "../components/seo"
import ReactMarkdown from "react-markdown"
import tw from "twin.macro"
import { SubcategorySection, CategoryProductsSection } from "../components"
import YouTube from "react-youtube"
import _ from "lodash"
import { getYoutubeVideoId } from "../utils/youtube"

const { Title, Text } = Typography

const HeroImage = styled(Img)`
  width: 60vw;
  height: 25vw;
  max-width: ${p => 400 * p.aspectRatio}px;
  max-height: 400px;

  @media (max-width: 900px) {
    width: 90vw;
    height: 40vw;
  }
`

const DescriptionContainer = styled.div`
  width: 60vw;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
  max-width: 950px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`
const DescriptionMarkdown = styled(ReactMarkdown)`
  ${tw`text-grey-darkest `}
  ul {
    list-style: inherit;
  }
`

const ProductCategoryTemplate = ({ data, intl }) => {
  const size = useWindowSize()
  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">{intl.formatMessage({ id: "home" })}</Link>
    </Breadcrumb.Item>,
    <Breadcrumb.Item key="home">
      <Link to="/products">{intl.formatMessage({ id: "products" })}</Link>
    </Breadcrumb.Item>,
    <Breadcrumb.Item key="home">
      <Link to="/products">{intl.formatMessage({ id: "categories" })}</Link>
    </Breadcrumb.Item>,
    <Breadcrumb.Item key={data.category.slug}>
      <Link to={`/${data.category.slug}`}>{data.category.title}</Link>
    </Breadcrumb.Item>
  ]

  const opts = {
    height: size.width > 900 ? "490" : `${size.width * 0.5}`,
    width: size.width > 900 ? "900" : `${size.width * 0.8}`,
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      // autoplay: 1,
    }
  }

  const onReady = event => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo()
  }

  return (
    <Container>
      <SEO title={data.category.title} />
      <WhiteSpace size={75} />
      <MaxWidth>
        <Breadcrumb>{breadcrumbItems}</Breadcrumb>
        <WhiteSpace size={50} />
        <Row justify="center">
          <Title level={3}>{data.category.title}</Title>
        </Row>
        <WhiteSpace size={15} />
        <Row justify="center">
          <HeroImage
            aspectRatio={data.category.heroImage.sizes.aspectRatio}
            sizes={data.category.heroImage.sizes}
            alt={data.category.heroImage.title}
          />
        </Row>

        {data.category.description && (
          <DescriptionContainer>
            <WhiteSpace size={50} />
            <Row justify="center">
              <Text strong>{intl.formatMessage({ id: "description" })}</Text>
            </Row>
            <WhiteSpace size={10} />

            <DescriptionMarkdown
              source={data.category.description.description}
            />
          </DescriptionContainer>
        )}

        {data.category.videoLink && (
          <>
            <WhiteSpace size={25} />
            <Row justify="center">
              <YouTube
                videoId={getYoutubeVideoId(data.category.videoLink)}
                opts={opts}
                onReady={onReady}
              />
            </Row>
            <WhiteSpace size={25} />
          </>
        )}

        <CategoryProductsSection
          category={data.category}
          products={data.products}
        />
        <SubcategorySection
          category={data.category}
          subcategories={data.subcategories}
        />
      </MaxWidth>
    </Container>
  )
}

// Hook
function useWindowSize() {
  const isClient = typeof window === "object"

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    }
  }

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (!isClient) {
      return false
    }

    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [getSize, isClient]) // Empty array ensures that effect is only run on mount and unmount

  return windowSize
}

export const query = graphql`
  query($slug: String!, $locale: String) {
    category: contentfulProductCategory(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      id
      title
      slug
      description {
        description
      }
      image {
        sizes(quality: 100) {
          ...GatsbyContentfulSizes_withWebp
        }
      }
      heroImage {
        sizes(quality: 100, maxHeight: 400) {
          aspectRatio
          ...GatsbyContentfulSizes_withWebp
        }
      }
      videoLink
    }
    subcategories: allContentfulProductSubcategory(
      filter: {
        category: { slug: { eq: $slug }, node_locale: { eq: $locale } }
      }
    ) {
      nodes {
        id
        title
        image {
          sizes(quality: 100, maxWidth: 400) {
            ...GatsbyContentfulSizes_withWebp
          }
        }
        product {
          id
          slug
          title
          new
          images {
            sizes(quality: 100, maxWidth: 400) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
        }
      }
    }
    products: allContentfulProduct(
      filter: {
        category: { slug: { eq: $slug }, node_locale: { eq: $locale } }
      }
    ) {
      nodes {
        id
        slug
        title
        new
        images {
          sizes(quality: 100, maxWidth: 400) {
            ...GatsbyContentfulSizes_withWebp
          }
        }
      }
    }
  }
`

export default injectIntl(ProductCategoryTemplate)
